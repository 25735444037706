"use client";

import { useEffect } from "react";

// TODO: SCOPE THE CSS, it's leaking styles and causing conflicts
// import "./error.css";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    if (error.digest) {
      console.error(error.digest);
    }
    console.error(error);
  }, [error]);

  return (
    <div
      onKeyUp={() => {
        reset();
      }}
      className="error-main"
    >
      <div className="notfound">
        <div className="centered">
          <span className="inverted">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          &nbsp;
        </div>
        <div className="centered">
          <span className="inverted">&nbsp;5&nbsp;0&nbsp;0&nbsp;</span>
          <span className="shadow">&nbsp;</span>
        </div>
        <div className="centered">
          <span className="inverted">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="shadow">&nbsp;</span>
        </div>
        <div className="centered">
          &nbsp;
          <span className="shadow">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">
          A fatal exception has occurred at C0DE: {error.message}
        </div>
        <div className="row">
          Sometimes refreshing the page solves the issue.
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">* Press any key to refresh the page.</div>

        <div className="row">&nbsp;</div>
        <div className="centered">
          Press any key to refresh the page...
          <span className="blink">&#9608;</span>
        </div>
      </div>
    </div>
  );
}
